// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/no-use-before-define */

import * as React from 'react';

import styled, { CSSObject } from '@emotion/styled';

import useBreakpoint from '@zego/hooks/useBreakpoint';
import { BREAKPOINTS, unit } from '@zego/theme';

import HeroKicker from '@zego/components/atoms/HeroKicker';
import HeroTitle from '@zego/components/atoms/HeroTitle';
import Spacer from '@zego/components/atoms/Spacer';
import View from '@zego/components/atoms/View';
import ContentContainer from '@zego/components/SCContentContainer';
import StaticImage from '@zego/components/StaticImage';

type OptionalImage = string | null;

export interface HeroProps {
  kicker?: React.ReactNode;
  title: React.ReactNode;
  readonly backgroundImageRatio?: [number, number];
  readonly backgroundImage: [OptionalImage, OptionalImage];
  backgroundImageAlt?: string;
  backgroundImageStyle?: CSSObject;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function Hero({
  kicker,
  title,
  backgroundImage,
  backgroundImageAlt,
  backgroundImageStyle,
  backgroundImageRatio,
  footer,
  children,
}: HeroProps): JSX.Element {
  const breakpoint = useBreakpoint();
  const shouldShowMediumBackground = breakpoint === 'small';
  const shouldShowLargeBackground = !shouldShowMediumBackground;
  const mediumImageRatio = backgroundImageRatio ? backgroundImageRatio[0] : 1;
  const largeImageRatio = backgroundImageRatio ? backgroundImageRatio[1] : 1;

  return (
    <Container>
      <BackgroundImageContainer css={backgroundImageStyle}>
        {shouldShowLargeBackground && (
          <StaticImage
            src={backgroundImage[1]}
            alt={backgroundImageAlt}
            ratio={largeImageRatio}
            css={{
              display: 'block',
              marginLeft: 'auto',
              height: 512,
              width: largeImageRatio * 512,
              [BREAKPOINTS.normal]: {
                height: 768,
                width: largeImageRatio * 768,
              },
            }}
          />
        )}
        {backgroundImage[0] && shouldShowMediumBackground && (
          <StaticImage
            src={backgroundImage[0]}
            alt={backgroundImageAlt}
            ratio={mediumImageRatio}
            css={{
              width: '100%',
              height: 'auto',
              display: 'block',
            }}
          />
        )}
      </BackgroundImageContainer>
      <HeroPageContainer>
        <Spacer height={unit(4)} css={{ [BREAKPOINTS.medium]: { flex: 1 } }} />
        {kicker && (
          <HeroKicker imageWidth={largeImageRatio}>{kicker}</HeroKicker>
        )}
        <Spacer height={unit(3)} />
        <HeroTitle imageWidth={largeImageRatio}>{title}</HeroTitle>
        <Spacer height={unit(4)} />
        {children}
        <Spacer height={unit(5)} css={{ [BREAKPOINTS.medium]: { flex: 1 } }} />
        {footer && (
          <>
            {footer}
            <Spacer height={unit(5)} />
          </>
        )}
      </HeroPageContainer>
    </Container>
  );
}

/**
 * Styled components
 */

const Container = styled(View)({
  position: 'relative',
  background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(245,244,255,1) 100%)`,
  display: 'flex',
  flexDirection: 'column-reverse',
  [BREAKPOINTS.medium]: {
    display: 'block',
  },
});

const HeroPageContainer = styled(ContentContainer)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  [BREAKPOINTS.medium]: {
    height: 512,
  },
  [BREAKPOINTS.normal]: {
    width: 'auto',
    height: 768,
  },
});

const BackgroundImageContainer = styled(View)({
  overflow: 'hidden',
  height: '100%',
  [BREAKPOINTS.medium]: {
    position: 'absolute',
    top: 0,
    left: '50%',
    right: 0,
  },
});
