import styled from '@emotion/styled';

import { BREAKPOINTS, baseText, disabledColor } from '@zego/theme';

const H4 = styled.h4(baseText, {
  fontWeight: 700,
  fontSize: 16,
  color: disabledColor(10),
  lineHeight: '26px',
  letterSpacing: -0.5,
  [BREAKPOINTS.small]: {
    fontSize: 22,
    lineHeight: '22px',
  },
});

export default H4;
