// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';

function getBreakpoint(size: number): string {
  if (size >= 1200) return 'large';
  if (size >= 1024) return 'normal';
  if (size >= 768) return 'medium';
  return 'small';
}

export default function useBreakpoint(): string {
  const [breakpoint, setBreakpoint] = useState('normal');

  const onResize = useCallback(() => {
    const size = window.innerWidth;
    setBreakpoint(getBreakpoint(size));
  }, []);

  // Call on mount
  useEffect(() => {
    onResize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return breakpoint;
}
