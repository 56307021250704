// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';

import { unit } from '@zego/theme';
import { BREAKPOINTS } from '@zego/theme';

import H4 from '@zego/components/atoms/H4';

export default function HeroKicker({ children, imageWidth }): JSX.Element {
  return (
    <H4
      css={{
        width: '100%',
        borderRadius: unit(1),
        fontWeight: 450,
        color: '#27262D',
        [BREAKPOINTS.smallOnly]: {
          fontSize: 18,
          fontWeight: 400,
          lineHeight: '20px',
        },
        [BREAKPOINTS.medium]: {
          width: `calc(100% - ${imageWidth}*512px)`,
        },
        [BREAKPOINTS.normal]: {
          width: `calc(100% - ${imageWidth}*768px)`,
        },
      }}
    >
      {children}
    </H4>
  );
}
